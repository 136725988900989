<template>
    <b-modal size="lg"
             hide-footer
             v-bind:visible="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal"
             v-on:close="hideModal">
        <template v-slot:modal-title>
            Assign user <strong>{{ name }} ({{ vendorId }})</strong> to groups
        </template>

        <ul class="list-group shadow-sm">
            <li class="position-relative search-bar list-group-item bg-light px-2">
                <b-form-input class="rounded-pill"
                              placeholder="🔍 Search"
                              v-model="keyword">
                </b-form-input>
                <button class="position-absolute btn btn-clear btn-link text-dark mr-2"
                        v-show="keyword"
                        v-on:click="keyword = ''">
                    <font-awesome-icon icon="times"></font-awesome-icon>
                </button>
            </li>
            <group-item v-for="chat in keywordFilteredChats"
                        v-bind:key="chat._id"
                        v-bind:chat="chat"
                        v-bind:account="account">
            </group-item>
        </ul>
    </b-modal>
</template>

<script>
import Fuse from "fuse.js";

import {
    BModal,
    BFormInput,
} from "bootstrap-vue";

export default {
    name: "ModalGroups",
    components: {
        "GroupItem": () => import("./ModalGroups/GroupItem"),
        BModal,
        BFormInput,
    },
    props: {
        "value": {
            type: Boolean,
        },

        "account": {
            type: Object,
        },
    },
    data () {
        return {
            keyword: "",
        };
    },
    computed: {
        name () {
            return this.account?.name ?? "";
        },

        vendorId () {
            return this.account?.vendorId ?? "";
        },

        chats () {
            return this.$store.state.whatsapp.chats;
        },

        inChatIds () {
            return Array.isArray(this.account?.chats) ?
                this.account
                    .chats
                    .map(
                        ({ _id }) => _id
                    ) :
                [];
        },

        sortedChats () {
            return this.chats
                .slice(0)
                .sort(
                    a => {
                        if (a.members.length >= 256) {
                            return 1;
                        }
                        return -1;
                    }
                )
                .sort(
                    (a, b) => {
                        if (this.inChatIds.includes(a._id) === true) {
                            if (this.inChatIds.includes(b._id) === false) {
                                return -1;
                            }
                            return 0;
                        } else {
                            if (this.inChatIds.includes(b._id) === false) {
                                return 0;
                            }
                            return 1;
                        }
                    }
                );
        },

        fuseInstance () {
            return new Fuse(
                this.sortedChats,
                {
                    "keys": [
                        "name",
                    ]
                }
            );
        },

        keywordFilteredChats () {
            if (this.keyword.length > 0) {
                return this.fuseInstance
                    .search(this.keyword)
                    .map(({ item }) => item);
            }
            return this.sortedChats;
        },
    },
    methods: {
        hideModal () {
            this.$emit("input", false);
        },
    },
}
</script>

<style lang="scss" scoped>
.search-bar {
    .btn-clear {
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }
}
</style>
